import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL + "/update/privacy";
const Get_url = process.env.REACT_APP_API_URL + "/get/privacy";

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST CATEGORY API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PrivacyApi = createAsyncThunk(
  "PrivacyApi",
  async ({ Token, values }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.put(Base_url, values, { headers });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500 || error.response.status === 400) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

export const GetPrivacyApi = createAsyncThunk(
  "GetPrivacyApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(Get_url, { headers });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// //%%%%%%%%%% CHECK PRIVACY PASSWORD API %%%%%%%%%%%%
// export const checkPrivacyPasswordApi = createAsyncThunk(
//   "checkPrivacyPasswordApi",
//   async ({ Token, values, id }) => {
//     const headers = {
//       "Content-type": "application/json",
//       Authorization: `Bearer ${Token}`,
//     };
//     try {
//       const response = await axios.put(
//         `${UPDATE_ADMIN_PRIVACY_SETTING_URL}`,
//         values,
//         { headers }
//       );
//       if (response.data.status === true) {
//         SuccessAlert(response.data.message);
//       } else {
//         ErrorAlert(response.data.message);
//       }
//       return response.data;
//     } catch (error) {
//       console.error("error", error);
//       if (error.response.status === 500 || error.response.status === 400) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

const DrawerSlice = createSlice({
  name: "DrawerOpenAndClose",
  initialState: {
    privacyOpen: null,
    status: null,
    message: null,
  },
  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%%%%%% POST API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PrivacyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PrivacyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PrivacyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      if (action.payload.status === true) {
        state.privacyOpen = action.payload?.result?.privacy_status;
      }
    });

    // // //%%%%%%%%%%%%%%%%%%% PRIVACY SETTING HANDLE %%%%%%%%%%%%%%%%%%%%%
    // builder.addCase(checkPrivacyPasswordApi.rejected, (state, action) => {
    //   state.iserror = "Client side Error";
    // });
    // builder.addCase(checkPrivacyPasswordApi.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(checkPrivacyPasswordApi.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.status = action.payload.status;
    //   state.message = action.payload.message;
    //   if (action.payload.status === true) {
    //     state.privacyEnable = true;
    //   }
    // });

    //%%%%%%%%%%%%%%%%%%%%%%% GET API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetPrivacyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetPrivacyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetPrivacyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      if (action.payload.status === true) {
        state.privacyOpen = action.payload?.result?.privacy_status;
      }
    });
  },
});

export default DrawerSlice.reducer;
