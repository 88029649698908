import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#252525",
    },
  },

  // 1) Paper
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          // boxShadow: '1px 0px 20px rgba(0, 0, 0, 0.05)',
          boxShadow: "1px 0px 20px rgba(0, 0, 0, 0.05)",
          backdropFilter: "blur(10px)",
        },
      },
    },

    // 2) textField

    MuiTextField: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#909090",
            transition: "border-color 0.3s ease-in-out",
          },
          "&:focus": {
            outline: "none", // Removes the outline on focus for buttons
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none", // Removes the outline on focus for buttons
          },
        },
      },
    },

    button: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none", // Removes the outline on focus for buttons
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none", // Removes the outline on focus for buttons
          },
        },
      },
    },

    MuiDataGridColumnHeader: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none", // Removes the outline on focus for buttons
          },
        },
      },
    },
  },
});

export default theme;
